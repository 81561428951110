.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-logo {
  margin-left: 10px;
}

.right-nav {
  margin-right: .5%;
}

#username {
  font-weight: bold;
}

.signedIn {
  margin-right: 10px;
  margin-top: 6px;
}

.dropdownLink {
  color: #dee2e6;
  text-decoration: none;
}

.dropdownLink:hover {
  color: #dee2e6;
}

#request-dropdown {
  margin-left: 0px;
  border-left: 1px solid rgba(255,255,255,.55);
}

.brand-name {
  margin-right: 10px;
}

input[type="text"], input[type="date"] {
  width: 100%;
  padding: 5px 5px 9px 5px;
  border-radius: 4px;
  display: inline-block;  
  align-self: left;
  border: 1px solid black;
}

.page-container {
  width: 90%;
  margin-left: 5%;
}

#root {
  background-color: lightgray;
}

#gradient-div {
  width:100%;
  height: 600px;
  background-image: linear-gradient(to bottom right, #AF282E, #0E5688);
  color: lightgrey;
  font-family: 'MS Serif';
  display: flex;
}

#welcome-text {
  font-size: 72px;
  text-shadow: -3px -3px black;
}

#signin-text {
  text-shadow: -2px -2px black;
}

#landing-photo {
  width: 95%;
  box-shadow: -1em -0.5em .4em black;
}

.landing-left {
  flex: 68%;
  padding-left: 12%;
  padding-top: 150px;
}

.landing-right {
  flex:32%;
  padding-top: 150px;
  padding-right: 5%;
}

#landing-info {
  width:100%;
  height: 200px;
  /* background-color: grey; */
  background-image: linear-gradient(to bottom left, #0E5688, lightgray);
  color: lightgrey;
  font-family: 'MS Serif';
}

#landing-button {
  width: 20%;
  margin-left: 40%;
  margin-top: 75px;
  font-weight: bold;
}