.request-column-holder {
    display: flex;
}

#comments {
    width: 100%;
    height: 200px;
}

.page-title {
    text-decoration: underline;
}

.submit-button {
    width: 25%;
    margin-left: 37.5%;
    border: 1px solid black;
}

.request-form-preview {
    width: 100%;
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
}

.iss-logo {
    margin-top: 15px;
    margin-bottom: 15px;
}

.request-lookup-values {
    margin-left: 15%;
}

.practitioner-buttons {
    width: 20%;
    margin-left: 20%;
    border: 1px solid black;
}

.generate-button {
    width: 40%;
    margin-left: 20%;
    border: 1px solid black;
}

.practitioner-dropdown {
    width: 40%;
    margin-left: 40%;
    border: 1px solid black;
    border-radius: 5px;
}

.generate-container {
    display: flex;
    
}

.dropdown-container {
    flex: 50%;
}

.button-container {
    flex: 50%;
}

.verification-buttons {
    width: 20%;
    margin-left: 20%;
    border: 1px solid black;
}

.pdfView {
    background-color: whitesmoke;
    margin: 3%;
    margin-top: 1%;
    margin-bottom: 1%;
    border: rgb(0, 0, 0) 2px solid;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 192px;
}

.rejection-notice {
    color: red;
    text-align: center;
}

.request-dropdowns{
    border: 1px solid black;
    border-radius: 5px;
}

.user-feedback {
    width: 18%;
    margin-right: auto;
    margin-left: auto;
}

#reject-reason {
    width: 40%;
    margin-right: auto;
    margin-left: auto;
}

.practitioner-modal {
    width: 40vw;
    max-width: 40vw;
}